<template>
  <a
    href="https://support.comeen.com/"
    class="flex items-center gap-x-4 px-8 py-6 text-lg font-semibold leading-6 text-gray-900"
    target="_blank"
  >
    <FontAwesomeIcon
      :icon="faGear"
      class="text-2xl shrink-0"
      aria-hidden="true"
    />
    <span class="sr-only">Help center link</span>
    <span aria-hidden="true">{{ t('resources.settings.name', 3) }}</span>
    <FontAwesomeIcon
      :icon="faArrowRight"
      class="h-5 w-5 shrink-0"
      aria-hidden="true"
    />
  </a>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGear, faArrowRight } from '@fortawesome/pro-regular-svg-icons'

const { t } = useI18n()
</script>
